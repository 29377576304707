<template>
  <div class="address-add">
    <div class="form">
      <div class="form-item">
        <div class="label"><span>*</span>区域地址：</div>
        <div class="data-value">
           <el-cascader
           placeholder="请选择省/市/区"
            style="width: 100%;"
            v-model="cityValue"
            :props="{ expandTrigger: 'hover' }"
            :options="cityOptions"></el-cascader>
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>详细地址：</div>
        <div class="data-value">
          <input maxlength="250" v-model="params.address" type="text" placeholder="请输入详细地址">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>收货人：</div>
        <div class="data-value">
          <input v-model="params.consignee" maxlength="15" type="text" placeholder="请输入收货人姓名">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>联系方式：</div>
        <div class="data-value">
          <input maxlength="30" v-model="params.contact" type="text" placeholder="请输入收货人联系方式">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span></span>邮政编码：</div>
        <div class="data-value">
          <input maxlength="6" v-model="params.postal_code" type="text" placeholder="请输入邮政编码">
        </div>
      </div>
      <div class="set-default">
        <span @click="setDefault(0)" v-if="params.is_default === 1" style="color: #D9262C;" class="icon iconfont icon-fuxuankuang"></span>
        <span @click="setDefault(1)" v-else class="icon iconfont icon-fuxuankuang1"></span>
        设为默认
      </div>
      <div class="actions">
        <div @click="saveHandle" class="btn save">保存</div>
        <div @click="hideHandle" class="btn cancel">取消</div>
      </div>
    </div>
  </div>
</template>
<script>


import {  pcaTextArr} from 'element-china-area-data' //地址库

export default {
  props: {
    editId: {
      type: Number,
      require: false
    }
  },
  data () {
    return {
      cityValue: [],
      cityOptions: [],
      params: {
        address: '',
        consignee: '',
        contact: '',
        postal_code: '',
        is_default: 0
      }
    }
  },
  created () {
    //console.log( "=====================",provinceAndCityData, pcTextArr, regionData, pcaTextArr, codeToText)
    // City.forEach(item => {
    //   item.children = item.city || []
    //   item.label = item.name
    //   item.value = item.name
    //   item.children.forEach(val => {
    //     val.children = val.area || []
    //     val.label = val.name
    //     val.value = val.name
    //     val.children.forEach(area => {
    //       area.label = area.name
    //       area.value = area.name
    //     })
    //   })
    // })
    this.cityOptions =pcaTextArr
    //this.cityOptions = City
    if (this.editId) {
      console.log(this.editId)
      this.getAddressDetail()
    }
  },
  methods: {
    // 获取地址详情
    async getAddressDetail () {
      const res = await this.$http.get(`api/address/${this.editId}`, {})
      console.log(res)
      // this.cityValue[0] = res.province
      // this.cityValue[1] = res.city
      // this.cityValue[2] = res.area
      this.cityValue = [res.province, res.city, res.area]
      this.params.address = res.address
      this.params.consignee = res.consignee
      this.params.contact = res.contact
      this.params.postal_code = res.postal_code
      this.params.is_default = res.is_default
      // this.$forceUpdate()
    },
    // 取消
    hideHandle (type) {
      this.$emit('hideModal',{type})
    },
    // 新增地址
    async saveHandle () {
      if (this.cityValue.length !== 3) {
        this.$message({
          type: 'error',
          message: '请选择省/市/区'
        })
        return false
      }
      if (!this.params.address) {
        this.$message({
          type: 'error',
          message: '请输入详细地址'
        })
        return false
      }
      if (!this.params.consignee) {
        this.$message({
          type: 'error',
          message: '请输入收货人姓名'
        })
        return false
      }
      if (!this.params.contact) {
        this.$message({
          type: 'error',
          message: '请输入收货人联系方式'
        })
        return false
      }
      if (this.params.postal_code && this.params.postal_code.length != 6) {
           this.$message({
            type: 'error',
            message: '请输入6位有效邮政编码'
          })
          return false
      }
      // if (!this.params.postal_code) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请输入邮政编码'
      //   })
      //   return false
      // }
      const params = this.params
      params.province = this.cityValue[0]
      params.city = this.cityValue[1]
      params.area = this.cityValue[2]
      if (this.editId) {
        await this.$http.put(`api/address/${this.editId}`, this.params)
      } else {
        await this.$http.post('/api/address', this.params)
      }
      this.hideHandle('confirm')
    },
    // 设置默认地址
    setDefault (value) {
      console.log(value)
      this.params.is_default = value
    }
  }
}
</script>
<style lang="scss" scoped>
.address-add {
  width: 450px;
  .form {
    padding-bottom: 20px;
    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .label {
        min-width: 78px;
        text-align: right;
        span {
          color: red;
        }
      }
      .data-value {
        flex: 1;
        input {
          width: 100%;
          line-height: 40px;
          outline: none;
          border: 1px solid #e6e6e6;
          padding: 0 13px;
          border-radius: 2px;
          &:focus {
            border-color: #D9262c;
          }
        }
        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
    .set-default {
      display: flex;
      align-items: center;
      padding-left: 77px;
      .iconfont {
        font-size: 17px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      .btn {
        width: 137px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 3px;
        margin: 0 10px;
        &:hover {
          opacity: .8;
        }
        &.save {
          background-color: #D9262c;
          color: #ffffff;
        }
        &.cancel {
          border:1px solid #D9262c;
          color: #D9262c;
        }
      }
    }
  }
}
</style>
